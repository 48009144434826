@import '../../mixins';
@import '../../theme';

.ContentContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ContentCard {
  flex: 1 0 auto;
  background-image: url('../../images/trees.jpg') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: right calc(100% + 165px) !important;
  padding-bottom: 80px !important;
  background-color: white !important;
  background-blend-mode: hard-light !important;
}

.NameDivider {
  :global .ant-divider-horizontal {
    margin: 0 0 12px !important;
  }
}

@keyframes float {
  0% {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.4);
    transform: translateY(0px);
  }
  50% {
    box-shadow: 0 25px 10px 0 rgba(0, 0, 0, 0.2);
    transform: translateY(-20px);
  }
  100% {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.4);
    transform: translateY(0px);
  }
}