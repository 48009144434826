@import './theme';
@import './mixins';

.App:not(.Small) {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.App.Small {

}

.MainContainer {
  max-width: 900px;
}

.Blur {
  filter: blur(2px);
  transition: filter 200ms ease-in;
}

.FullWidth {
  width: 100%;
}

.LeftIcon {
  margin-right: 8px;
}

.RightIcon {
  margin-left: 8px;
}

.RowSpacebetween {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.ButtonContainer {
  width: 100%;
  margin-top: 8px !important;
  &.RaisedButtons {
    .ant-btn {
      @include shadow;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600 !important;
}


/**
  Custom Antd themes
 */

.ant-btn {
  border-width: 2px !important;
  font-weight: 600 !important;
  height: 36px !important;
  border-radius: 20px !important;
}

.ant-drawer-close:hover {
  color: $rose !important;
}

.ant-card {
  display: flex !important;
  flex-direction: column !important;
  position: relative !important;
  padding: 30px 24px !important;
  border-radius: 20px !important;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.4) !important;
  background-color: white !important;
  box-sizing: content-box !important;
  overflow: hidden;
}

.ant-card-body {
  padding: 0 !important;
}